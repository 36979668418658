.airlineStops{
  .form-check-input{
    background-color: red;
  }
  .container:hover input .checkmark {
    background-color: red;
  }
  .container input:checked .checkmark {
    background-color: green;
  }

.span_pseudo, .custom-checkbox span:before, .custom-checkbox span:after {
  content: "";
  display: inline-block;
  background: #000;
  width: 0;
  height: 0.2rem;
  position: absolute;
  transform-origin: 0% 0%;
}
.custom-checkbox {
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;
}
.custom-checkbox input {
  display: none;
}
.custom-checkbox input:checked ~ span {
  background: #c2db83;
  border-color: #c2db83;
}
.custom-checkbox input:checked ~ span:before {
  width: 1rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.3s;
}
.custom-checkbox input:checked ~ span:after {
  width: 0.4rem;
  height: 0.15rem;
  transition: width 0.1s;
  transition-delay: 0.2s;
}
.custom-checkbox input:disabled ~ span {
  background: #ececec;
  border-color: #dcdcdc;
  
}
.custom-checkbox input:disabled ~ label {
  color: #dcdcdc;
}
.custom-checkbox input:disabled ~ label:hover {
  cursor: default;
}
.custom-checkbox label {
  padding-left: 2rem;
  position: relative;
  // z-index: 2;
  cursor: pointer;
  margin-bottom:0;
}
.custom-checkbox span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  transition: all 0.2s;
  // z-index: 1;
  box-sizing: content-box;
  background: #c2db83;
  border-color: #c2db83;
}
.custom-checkbox span:before {
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}
.custom-checkbox span:after {
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

}
