.inactive-list{
    opacity: 0.6
}


.airport-option {
    .text-2 {
        font-size: 10px;
    }
}
