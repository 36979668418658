@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import '../../../styles/variables';

.booking-engine-header{
    .dropdown:hover>.dropdown-menu {
        display: block;
        background: #2394dc;
    }
    .dropdown-item:hover>.dropdown-menu {
        display: block;
    }
    .primary_color{ 
        background-color: $primary_color!important;
    };
    box-shadow: 0px 0px 2px 1px #a9a9a9;
    .navbar-brand {
        img {
            width: 75%;
        }
    }
    .navbar-logo{
        width: 360px!important;
    }
    .position-relative {
        position: relative!important;
    }
    header h6 span {
        color: #5aac62;
    }
    .background-color{
        background-color: #eaeaea;
    }
    .font-green{
        color: #5aac62;
    }
    .header-content{
        padding: 5px 0px;
        font-size: 20px;
        font-weight: bold;
    }
    .navbar {
        // padding: 0rem 1rem !important;
        padding-bottom: 3px;
    }
    .nav-link{
        color: white!important;
        padding: 12px 20px 10px 20px!important;
    }
    .nav-link.active, .nav-link:hover{
        border-bottom: 3px solid white;
        transition: 0.3s all;
        background: #057bb3;
    }
    .nav-btn {
        background: white;
        padding: 2px 10px;
        font-size: 15px;
        margin: 4px;
        color: black;
    }
    #yearsExcellence{
        font-size: 14px!important;
        span{
            font-size: 18px!important;
            font-weight: 900;
        }
    }
    #font-16{
        font-size: 16px!important;
    }
    .telephone{
        color: #007bff;
    }
    .mobile-navigation{
        .dropdown:hover>.dropdown-menu {
            display: unset!important;
            background: white!important;
            color: black!important;
            min-width: 8rem !important;
        }
        .dropdown-item:hover>.dropdown-menu {
            display: unset!important;
        }
        a{
            color: unset!important;
        }
        @include media-breakpoint-down(md){
            display: block !important;
            width: 100% !important;
            padding: 10px 30px;

            .nav-btn{
                padding: 12px 30px!important;
                width: -webkit-calc(  50% - 10px  );
            }
        }
        .dropdown-toggle{
            width: 100%!important;
        }
    }
        
    @include media-breakpoint-down(md){
        .navbar-nav{
            width: 100% !important;
            margin-top: unset!important;
            margin-bottom: unset!important;
        }
        .nav-link.active, .nav-link:hover{
            border-bottom: unset!important;
        }
        .navbar {
            .navbar-toggler {
                z-index: 1000;
            }
            .navbar-collapse {
                background: #2394dc;
                z-index: 1010!important;
                opacity: 0;
                position: fixed;
                top: 0; left: 0; right: 0; bottom: 0;
                transition: opacity 0.5s ease;
                display: flex;
                flex-direction: column;
                align-content: center;
                text-align: center !important;
                &.collapse {
                    opacity: 0;
                    display: flex;
                    pointer-events: none;
                }
                &.collapsing[style*="height"] {
                    opacity: 1;
                    height: 100vh !important;
                }
                &.collapsing {
                    opacity: 0;
                    height: 100vh;
                }
                &.collapse.show {
                    opacity: 1;
                    pointer-events: auto;
                }
                .navbar-nav {
                    margin-right: initial !important;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .fa{
                color: white;
            }
            .close-button{
                align-self: flex-end!important;
                background: unset!important;
                border: unset!important;
            }
        }
        .dropdown-item{
            text-align: center!important;
        }
    }    

}
