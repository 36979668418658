@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-down(md) {
  .vertical-divider {
      display: none !important;
  }
}

.vertical-divider {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    width: auto;
    height: 50%;
    line-height: 0;
    text-align:center;
    text-transform: uppercase;
    transform: translateX(-50%);
  }
  
  .vertical-divider:before, 
  .vertical-divider:after {
    position: absolute;
    left: 50%;
    content: '';
    z-index: 9;
    border-left: 1px solid rgba(34,36,38,.15);
    border-right: 1px solid rgba(255,255,255,.1);
    width: 0;
    height: calc(100% - 1rem);
  }
  
  .row-divided > .vertical-divider {
    height: calc(50% - 1rem);    
  }
  
  .vertical-divider:before {
    top: -100%;
  }
  
  .vertical-divider:after {
    top: auto;
    bottom: 0;
  }
  
  .row-divided {
    position:relative;
  }
  
  .row-divided > [class^="col-"],
  .row-divided > [class*=" col-"] {
    padding-left: 30px;  /* gutter width (give a little extra room) 2x default */
    padding-right: 30px; /* gutter width (give a little extra room) 2x default */
  }
  
  
  
  
  /* just to set different column heights - not needed to function */          
  .column-one {
    height: 300px; 
    background-color: #EBFFF9;
  }
  .column-two {
    height: 400px;
    background-color: #F7F3FF;
  }