@import '../../../../styles/variables';

.modal-agent-delete-confirm{
    .active-agent{
        color: $secondary_color !important;
    }
}

.modal-agent-details{
    z-index: unset !important;
}

.agentStatus{
    .form-control{
        width: 32px !important;
    }
}

.bgVusColor{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ADD8E6;
}
.itemBackColor{
    padding: 4px;
    background-color: #ffffff;
    border-radius: 5px;
}


