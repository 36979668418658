@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-down(md) {
    .flight-search-result-item {
        .flight-time{
            font-size: 25px !important;
        }
    }
}


.flight-search-result-item {
    .airline-logo{
        max-width: 75px;
        width: 100%;
    }
    .flight-icon {
        height: 23px;
        vertical-align: inherit;
    }
    .flight-time {
        font-size: 25px;
    }
    .time-range{
        font-size: 11px;
        line-height: 0.9;
    }

    #view-details button{
        color: black!important;
    }
    #view-details i{
        color: #0094da!important;
    }
    .card{
        border: unset!important;
        background-color: #F1F9FE;
    }
    
}