@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import '../../../../styles/variables';


.main-search-widget{
    .card{
        border: 1px solid black;

        .car-body{
        button{
            border-color: 1px solid black !important;
        }
        }
    }

    .for-iframe-booking-engine {
        @include media-breakpoint-up(lg) {
            padding-top: 67px !important;
        }
    
        @include media-breakpoint-up(md) {
                padding-top: 120px;
        }

        .flight-text-box{
            color: black;
            background-color: rgba(2, 170, 207, 0.75);
            padding: 0.25rem 0 0.25rem 0.5rem;
            width: 135px;
            border-top: 1px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }
    
    }



    .widget-wrapper-new {

        color: white;

        background-color: rgba(25, 117, 146, 0.75)!important;
        // &:hover{
        //     background-color: white!important;
        // }

        .widget-ico {
            width: 35px;
            height: 35px;
            display: inline-block;
            background-size: cover;
            
            &.ico-adults {
                background-position: 3%;
            }
            &.ico-children {
                background-position: 50%;
            }
            &.ico-infants {
                background-position: 100%;
            }
        }
        .counter-input-wrapper {
            input{
                background-color: #74b3c3 !important;
                height: 25px !important;
            }
            div {
                div {
                    &:first-child {
                        background: unset;
                        border-radius: 50%;
                        width: 28px;
                        border-style: solid;
                        color: #FFF;
                        padding: unset!important;
                        margin-right: 3px;
                    }
                    &:last-child {
                        background: unset;
                        border-radius: 50%;
                        width: 28px;
                        border-style: solid;
                        color: #FFF;
                        padding: unset!important;
                        margin-left: 3px;
                    }
                }
            }

            
        }
        .react-datepicker-wrapper {
            width: 100% !important;    
            .react-datepicker__input-container{
                width: 100% !important;

                input{
                    border: 1px solid white !important;
                    color: white !important;
                }

                .form-control::placeholder {
                    color: white !important;
                }
            }
        }
        .airport-option {
            .text-2 {
                font-size: 10px;
            }
        }
        .btn-outline-primary, .btn-primary{
            border-color: black !important;
        }

        #select-buttons{
            button{
                background-color: #beeef6 ;
                color: black ;
            }
            button:hover {
                background-color: #007ab4 !important;
                color: white !important;
            }
            .active{
                background-color: $primary_color;
                color: white;
            }
        }

    }

    .css-yk16xz-control {
        border: 1px solid white !important;
        // background-color: rgb(11, 118, 146 / 75%) !important;
        .css-1uccc91-singleValue{
        color: white !important;
        }
        svg{
            color: white ;
        }
        .css-1okebmr-indicatorSeparator{
            background-color: white !important;
        }

        .css-1wa3eu0-placeholder{
            color: white !important;
        }
    }

    .widget-errors-alert {
        @include media-breakpoint-down(md) {
            position: absolute;
            bottom: 95px;
            width: 95%;
            margin: auto;
            right: 0;
            left: 0;
            min-height: 160px;
        }
        
    }
}
