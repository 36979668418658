a {
    cursor: pointer;
}

label {
    &.required {
        &::after {
            content: '*';
            color: #e4908b;
            padding: 5px;
        }
    }
}

.modal-title {
    color : $primary_color;
}

.modal-90w{
    max-width: 90%;
}

.modal-70w{
    max-width: 70%;
}

.modal-50w{
    max-width: 50%;
}

.modal-40w{
    max-width: 40%;
}

.modal-30w{
    max-width: 30%;
}

