.main-search-widget .trip-item{
    color: white;
    .css-yk16xz-control{
        // background-color: rgba(202, 229, 236, 0.6)!important;
        background-color: #15677f !important;
    }
    .css-26l3qy-menu{
        background-color: rgba(202, 229, 236, 0.6)!important;
        color: black;
    }
    .css-1pahdxg-control{
        background-color: #beeef6 !important;
    }
    .form-control{
        // background-color: rgba(202, 229, 236, 0.6)!important;
        background-color: #15677f !important;
    }
}
