.admin-home-banner{

    background: url('../../img/background.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    color: black;

    .myheight {
        min-height: 70vh !important;
    }

    .hoverEffect {
        &:hover{
        opacity: 0.7;
        }
    }

    .head-color{
        // color: #1B75BC;
        color: #022842;
    }

    .textDec{
        text-decoration: none!important;
    }
}