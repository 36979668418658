@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

.admin-header{
    color: black;
    a{
        color: white;
        @include media-breakpoint-down(sm){
            text-align: center;
        }
    }
    a:hover{
        color: white;
    }
    #logout{
        color: black!important;
    }
    .navbar-toggler-icon {
        color: white!important;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
    }
    .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: rgba(0,0,0,.1);
    }

    .dropdown-menu{
        left: unset !important;
        right: 0 !important;
    }

    .nav-item{
        .active{
            color: #dedede !important;
        }
    }
}
