@import '../../../../styles/variables';

.modal-agent-delete-confirm{
    .active-agent{
        color: $secondary_color !important;
    }
}

.modal-agent-details{
    z-index: unset !important;
}

.agentStatus{
    .form-control{
        width: 32px !important;
    }
}

.cus-margin{
    margin-left: 5px;
}