.search-loader{
    height: 80vh !important;
}
.loader-svg {
    #motionPath {
        fill:none;stroke:#746C65;stroke-miterlimit:10;stroke-dasharray:7.9932,4.9958;
    }
    .circ {
        fill:#A4CD34;
    }
    #plane {
        //transform: rotate(28deg) translateY(-84px) translateX(-74px);
        fill:#0094DA;
    }
}