@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-down(lg) {
        .modal-30w{
            max-width: 90% !important;
        }
}

@include media-breakpoint-down(sm) {
        .modal-30w{
            max-width: 100% !important;
        }
}

