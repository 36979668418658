/* Custom fonts */
@import "styles/fonts";
/* bootstrap modified variables and other variables */
@import "styles/variables";
/* Ladda */
@import "node_modules/ladda/css/ladda";
/* bootstrap */
@import "node_modules/bootstrap/scss/bootstrap";
/* bootstrap modified tyles */
@import "styles/bootstrap-mod";
/* custom client styles */
@import "styles/client";
/* device responsive styles */
@import "styles/responsive";
