.flight-summary-bar{
    color: #5A5F62;
    
    border-width: 12px;
    .border-left{
        border-left-style: solid!important;
        border-width: 2px!important;
        border-color: #C4E6FA!important;
    }
    .border-bottom{
        border-bottom-style: solid!important;
        border-width: 2px!important;
        border-color: #C4E6FA!important;
    }
}
