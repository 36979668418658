@import '../../../styles/variables';

footer{
    position: relative!important;
    a{
        font-size: 14px!important;
    }
    #airlines-logo a{
        padding: 10px 15px!important;
    }
    #bottom-footer li{
        padding: 7px 0px!important;
    }
    #social-media a{
        margin: 12px 16px!important;
    }
    #social-media a:hover{
        // margin: 12px 14px!important;
        opacity: .6;
    }
    h4{
        font-size: 1.25rem!important;
        color: white!important;
    }
    p{
        font-size: 14px!important;
        color: #cccbcb !important;
    }
    #airlines-logo img {
        height: 43px;
        padding: 5px 5px 5px 5px;
    }
    li a, li a:hover{
        color:#cccbcb !important;
        display: block;
    }
    ul{
        list-style-type:none!important;
        margin-block-start: 0px!important;
        margin-block-end: 0px!important;
        margin-inline-start: 0px!important;
        margin-inline-end: 0px!important;
        padding-inline-start: 0px!important;
    }
}
.booking-engine-footer{
    background: #60554c;
    .quick-links {
        /* padding: 20px; */
        background: #e8e8e8;
        border-top: 4px solid $primary_color_dark;
    }
    .footer-logo {
        width: 100%;
        opacity: 0.3;
        max-width: 175px;
    }
    .footer-social {
        font-size: 20px;
        a {
            color: #FFF;

            &:hover {
                color: #1b75bc;
            }
        }
    }
    .fa{
        color: white;
    }
    #travel-blog-topic{
        font-size: 1.25rem!important;
        color: white;
        display: block;
    }
}
.logo-img{
    display: inline-block;
}
.img-fluid{
    height: 150px;
    width: 150px;
    padding-left: 20px;
    padding-right: 20px;
}
.img-fluid-1{
    height: 60px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}
