html {
    position: relative;
    min-height: 100%;
}
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
}
body{
    position: relative;
}
/* page content wrapper */
.booking-engine-content{
    min-height: 600px;
    padding: 30px 0px;
    margin-top: 70px;
}

.btn-rounded {
    border-radius: 20px;
}
.datpicker-w-100-wrapper {
    .react-datepicker-wrapper {
        width: 100% !important;    
        .react-datepicker__input-container{
            width: 100% !important;    
        }
    }
}

.react-datepicker-popper {
    z-index: 2000;
}

.dark-backdrop {
    opacity: 0.8 !important;
}
.page-item.active .page-link {
    z-index: inherit;
} 
.sort-order h5 {
    font-size: 18px;
    margin-bottom: 0;
}
.search-result .pagination li a {
    font-size: 18px !important;
}
.booking-timer{
    background: #a6ce39;
        .timeleft{
            color:#fff !important;
        }
        .timelefttext{
            color: #3f5012 !important;
        }
}
.flight-name {
    color: #0094da;
}