@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

.widget-wrapper {
    .widget-ico {
        width: 35px;
        height: 35px;
        display: inline-block;
        background-size: cover;
        
        &.ico-adults {
            background-position: 3%;
        }
        &.ico-children {
            background-position: 50%;
        }
        &.ico-infants {
            background-position: 100%;
        }
    }
    .counter-input-wrapper {
        div {
            text-align: center!important;
            div {
                &:first-child {
                    background: #1b75bc;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    color: #FFF;
                    padding: 5px!important;
                }
                &:last-child {
                    background: #1b75bc;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    color: #FFF;
                    padding: 5px!important;
                }
            }
        }
    }
    .react-datepicker-wrapper {
        width: 100% !important;    
        .react-datepicker__input-container{
            width: 100% !important;    
        }
    }
    .airport-option {
        .text-2 {
            font-size: 10px;
        }
    }
    .search-button{
        @include media-breakpoint-up(lg){
            button{
                font-size: 15px;
                height: 45px;
                padding: unset!important;
            }
        }
    }
    .business{
        @include media-breakpoint-down(sm)  {
            margin-top: 0.25rem!important;
        }
    }
}
