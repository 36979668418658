@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

.search-result{
    .pagination{
        li a{
            color: black;
            font-size: 20px!important;
        }
    }
    .dropdown{      
        button{
            background-color: #C4E6FA;
        } 
        .btn-outline-info{
            color: unset!important;
            height: 44px!important;
        }
    }
    .full-width{
        width: 100%!important;
    }
    .modal-dialog{
        max-width: 60%!important;
    }
    .sort-order{
        color: #5A5F62;
    }
    .dropdown-toggle::after{
        content: unset!important;
    }
}
