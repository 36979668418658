/* Global vars */
$primary_color : #0094da;
$primary_color_dark : #0477ad;
$secondary_color : #dc3545;
// $secondary_color : #03a84e;
$dark_color:#000;
$success_color : #4ab54e;

$warning_color : #c2db83;
$info_color: #56b6f2;
$primary_font : 'Roboto', sans-serif;

/* Bootstrap overrides */
$theme-colors: (
  "primary":  $primary_color,
  "secondary": $secondary_color,
  "success" : $success_color,
  "info" : $info_color,
  "warning" : $warning_color,
  "dark" : $dark_color,
);
$body-bg : #fcfcfc;
$font-family-base : $primary_font;
$headings-font-family : $primary_font;
// $font-size-base : 1.29rem;
// $border-radius:0.6rem;
$input-btn-focus-width:.1rem;
// $border-width:0.5px;
$form-group-margin-bottom:0.5rem;
// $border-radius:15px;
$btn-border-radius:6px;
$btn-border-radius-lg:6px;
$btn-border-radius-sm:6px;
// $input-border-color:#808080;
// $input-bg : $dark_color;
// $input-color:$primary_color;

$modal-header-border-color: none;
$modal-content-border-radius:20px;