@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-up(lg) {
    .booking-engine-view{
        min-height: 600px;
        padding: 20px 0px 30px;
        margin-top: 135px;
    }
}

@include media-breakpoint-down(md){
    .booking-engine-view{
        min-height: 600px;
        padding: 25px 0px 30px;
        margin-top: 156px!important;
    }
}
