@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@include media-breakpoint-up(lg){
    .container {
        max-width: 1340px;
    }
}

@include media-breakpoint-down(lg){
    .modal-70w, .modal-50w{
        max-width: unset;
    }
}
@include media-breakpoint-down(sm){
    .sm-text-center{
        text-align: center !important;
    }
    h5, .h5 {
        font-size: 17px;
    }
    .sub-heading-1{
        font-size: 24px;
    }
    .btn {
        font-size: 0.85rem;
    }
    .airlineCodes, .airlineStops {
        font-size: 12px;
    }
    button h5{
        font-size: 14px;
        margin-bottom: 0;
    }
    .flight-name {
        font-size: 20px;
        color: #0094da;
    }
    .search-result .dropdown .btn-outline-info {
        height: 32px !important;
    }
    .search-result .pagination li a {
        font-size: 15px !important;
    }
    .form-control {
        font-size: 13px;
    }
    .css-g1d714-ValueContainer {
        font-size: 13px;
    }
    .justify-content-center-m {
        justify-content: center !important;
    }
    .flight-search-result-item #view-details i {
        display: block;
    }
    #view-details{
        margin-bottom: 10px;
    }
    .booking-engine-footer .quick-links {
        padding: 10px;
    }
    .footer-description{
        margin-top: 10px;
    }
    .card-title{
        font-size: 20px;
    }
    .form-group label {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .flight-summary-bar .border-left {
        border-left-style: solid !important;
        border-width: 0px !important;
        border-color: #ffffff !important;
    }
    .flight-search-result-item .airline-logo {
        max-width: 50px;
        margin-bottom: 5px;
    }
    footer .fa-3x {
        font-size: 1.8em;
    }
}
