.pointers{
    cursor: pointer;
}

.backcoloredit{
    color: #FFA900;
}

.backcolordelete{
    color: #F93154;
}