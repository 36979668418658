.booking-report{
    .card{
        border: unset !important;
        .card-header{
            background-color: unset !important;
            padding-left: unset !important;
        }
    }
}

#bookingsReports_wrapper {
    .row:nth-child(2) {
        overflow-x: scroll;
    }
}

table#bookingsReports {
    font-size: 12px;
}