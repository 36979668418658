@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import '../../../../styles/variables';

.fare-item-delete-confirm-modal{
    .title{
        color: $secondary_color !important;
    }
}

#card-body{
    p{
        font-size: 0.5rem;
    }
    h6{
        font-size: 0.8rem;
    }
    @include media-breakpoint-down(md){
        p{
            font-size: unset;
        }
        h6{
            font-size: 1.2rem;
        }
    }
    
    .card-body{
        padding: unset!important;
    }
    .card-header{
        padding: unset!important;
        padding-top: 5px!important;
    }
    
    .border-right{
        border-right: 1px solid #e3e3e3;
        @include media-breakpoint-down(md){
            border-right: unset!important;
        }
    }
    .border-left{
        border-left: 1px solid #e3e3e3;
    }
    .border-top{
        border-top: 1px solid #e3e3e3;
        @include media-breakpoint-down(md){
            margin-left: 2px;
        }
    }


//    .edit-button{
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//    }

//    .edit-button &:hover{
//     position: absolute;
//     top: 0;
//     left: 0;
//     background: rgba(0, 0, 0, 0.09);
//     height: 100%;
//     width: 100%;
//    }



    .resultContainer {width:100%;border-bottom:1px solid white;position:relative;}
    .viewThisResult {position:absolute;}
    .gray {
        background: none repeat scroll 0 0;
    }
    .resultContainer .viewThisResult {
        display: none;
    }
    .resultContainer:hover .viewThisResult {
        display: inline;
    }
    .resultContainer:hover{
        background-color: #F4F4F4;
    }

    .switch-off{
        // background-color: darkgray!important;
        opacity: 0.5;
        pointer-events: none;
    }


    
    // .switch-disable{
    //     width: 100%;
    //     // border-bottom: 1px solid white;
    //     position: relative;
    // }
    // .switch-disable-inside{
    //     position: absolute;
    // }
    // .switch-disable-background{
    //     background: none repeat scroll 0 0;
    // }
    // .switch-disable .switch-disable-inside {
    //     // display: none;
    //     // opacity: 0.5;
    // }
    // .switch-disable:hover .switch-disable-inside {
    //     display: inline;
    // }

    // .switch-disable{
    //     position: absolute;
    //     // display: none;
    //     opacity: 0.5;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-color: rgba(0,0,0,0.5);
    //     z-index: 2;
    //     cursor: pointer;
    // }



}
