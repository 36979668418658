$white: #eee;
//$p1: #E1E1E1;
//$p2: #CCCCCC;
//$p3: #AEAEAE;
$p1: #03a84e;
$p2: #0094da;
$p3: #99b8bc;
// scaling... any units
$width: 80px;

.ui-loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    // background: #FFF;
    overflow: hidden;

    .loader {
        position: relative;
        margin: 0px auto;
        width: $width;
        height: 100%;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
    @keyframes color {
        100%,
        0% {
            stroke: $p1;
        }
        40% {
            stroke: $p2;
        }
        66% {
            stroke: $p3;
        }
        80%,
        90% {
            stroke: $p2;
        }
    }

    &.small-loader {
        position: relative;
        .loader {
            width: 50px;
        }
    }
}
